<template>
    <div id="printInvoice" style="display: none">
        <div class="table-responsive">
            <div class="text-center">
                <!-- Logo -->
                <div class="col-12">
                    <div style="display: flex; justify-content: center;" class="d-flex align-items-center justify-content-center mt-5 mb-2">
                        <div class="logo me-2" style="width: 100px;">
                            <img style="height: 80px" class="img img-responsive" src="@/assets/logo.png" alt="logo">
                        </div>
                        <div class="name">
                            <h3 style="font-size: 41px; letter-spacing: 5px; color: #35CD56; margin-bottom: 0;line-height: 20px">পেইন কিউর ডেন্টাল কেয়ার</h3>
                            <h3 class="text-blue" style="font-size: 41px; letter-spacing: 7px; color: #242b6f">Pain Cure Dental Care</h3>
                        </div>
                    </div>
                    <div class="address text-center fs-12 mb-2">
                        <p style="font-size: 12px; color: #35CD56; margin-bottom: 0">722/4, West Shewrapara, Mirpur, Dhaka-1216, In Front of East West Int. School and
                            College</p>
                        <p style="color: #222;font-size: 11px;line-height: 5px;">website: paincuredentalcare.com | Phone: (+880) 1720-425485 </p>
                    </div>
                </div>

                <!-- Title of receipt -->
                <p style="color: #222;font-size: 11px; margin-top: 15px">
                    <span style=" border: 1px solid; border-radius: 15px;padding: 6px;">PAYMENT INVOICE</span>
                </p>
                <table style="width: 100%">
                    <tbody>
                    <tr>
                        <td style="text-align:left;color: #222;font-size: 11px;">Invoice No: {{
                                transaction.invoice_no
                            }}
                        </td>
                        <td style="text-align:right;color: #222;font-size: 11px;">Date:
                            {{ transaction.transaction_date |dateTimeFormat }}
                        </td>
                    </tr>
                    <tr>
                        <td style="color: #222;font-size: 11px;">Patient: {{
                                transaction.patient ? transaction.patient.name + " | " + transaction.patient.id_number
                                                    : ''
                            }}
                        </td>
                        <td style="text-align:right;color: #222;font-size: 11px;">Phone: {{
                                transaction.patient.phone
                            }}
                        </td>
                    </tr>
                    </tbody>
                </table>

                <table style="width: 100%;margin-top: 7px; border-bottom: 1px solid #222">
                    <tbody>
                    <tr style="border-top:1px solid #222; border-bottom: 1px solid #222;font-weight: bold">
                        <td style="text-align:left;color: #222;font-size: 11px; width:25%;">Service</td>
                        <td style="text-align:left;color: #222;font-size: 11px; width:25%;">Quantity</td>
                        <td style="text-align:left;color: #222;font-size: 11px; width:25%;">Amount</td>
                        <td style="text-align:right;color: #222;font-size: 11px; width: 25%;">Subtotal</td>
                    </tr>
                    <tr v-for="(transaction_item, index) in transaction.transaction_items">
                        <td colspan="4">
                            <table style="width: 100%">
                                <tr style="border-bottom: 1px solid #f5f5f5;">
                                    <td style="text-align:left;color: #222;font-size: 11px;">
                                        {{
                                            transaction_item.service ? transaction_item.service.name
                                                                     : ''
                                        }}
                                    </td>
                                    <td style="text-align:left;color: #222;font-size: 11px;width: 25%;">
                                        {{ transaction_item.quantity }}
                                    </td>
                                    <td style="text-align:left;color: #222;font-size: 11px;width: 25%;">
                                        {{ transaction_item.unit_price | numberFormat }}
                                    </td>
                                    <td style="text-align:right;color: #222;font-size: 11px;width: 25%;">
                                        {{ transaction_item.quantity * transaction_item.unit_price | numberFormat }}
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr style="border-top:1px solid #222;margin-top: 10px">
                        <td colspan="1">
                            <button
                                style="text-align:center; color: #222;font-size: 11px; text-transform: uppercase; font-weight: bold">
                                {{ transaction.payment_status }}
                            </button>
                        </td>
                        <td colspan="3" style="text-align:right;color: #222;font-size: 11px;">
                            <table style="width: 100%">
                                <tr style="margin-top: 10px">
                                    <td style="text-align:right;color: #222;font-size: 11px;font-weight: bold;">Sub
                                        total
                                    </td>
                                    <td style="text-align:right;color: #222;font-size: 11px;font-weight: bold;">
                                        {{ transaction.sub_total| numberFormat }}
                                    </td>
                                </tr>
                                <!-- Discount -->
                                <tr v-if="transaction.discount_amount>0">
                                    <td style="text-align:right;color: #222;font-size: 11px;">Discount</td>
                                    <td style="text-align:right;color: #222;font-size: 11px;">
                                        {{ transaction.discount_amount }}
                                    </td>
                                </tr>

                                <!-- Total Paid-->
                                <tr>

                                    <td style="text-align:right;color: #222;font-size: 11px;">Total Paid</td>
                                    <td style="text-align:right;color: #222;font-size: 11px;">
                                        {{ transaction.total_paid |numberFormat }}
                                    </td>
                                </tr>
                                <tr v-if="transaction.total_due>0">
                                    <td style="text-align:right;color: #222;font-size: 11px;"> Due</td>
                                    <td style="text-align:right;color: #222;font-size: 11px;">
                                        {{ transaction.total_due | numberFormat }}
                                    </td>
                                </tr>
                            </table>

                        </td>
                    </tr>
                    </tbody>
                </table>


                <!-- PAYMENT LIST -->
                <template
                    v-if="transaction.transaction_payments && transaction.transaction_payments.length">
                    <p style="color: #222;font-size: 11px; margin-top: 45px;margin-bottom: 15px">
                        <span style=" border: 1px solid; border-radius: 15px;padding: 6px;">PAYMENT LIST</span>
                    </p>
                    <table style="width: 100%;border-bottom: 1px solid #222">
                        <tbody>
                        <tr style="border-top:1px solid #222;border-bottom: 1px solid #222; font-weight: bold;">
                            <td style="text-align:left;color: #222;font-size: 11px; width:25%;">#</td>
                            <td style="text-align:left;color: #222;font-size: 11px; width:25%;">Date</td>
                            <td style="text-align:left;color: #222;font-size: 11px; width:25%;">Payment Method</td>
                            <td style="text-align:right;color: #222;font-size: 11px; width: 25%;">Amount</td>
                        </tr>
                        <tr v-for="(payment, index) in transaction.transaction_payments">
                            <th style="text-align:left;color: #222;font-size: 11px;" scope="row">{{ ++index }}</th>
                            <td style="text-align:left;color: #222;font-size: 11px;">{{ payment.paid_on |dateFormat }}</td>
                            <td style="text-align:left;color: #222;font-size: 11px; text-transform: capitalize;">{{ payment.method }}</td>
                            <td style="text-align:right;color: #222;font-size: 11px;">{{ payment.amount | numberFormat }}</td>
                        </tr>
                        </tbody>
                    </table>
                </template>
                {{ transaction.additional_notes }}
                <p style="color: #222;font-size: 11px; border-bottom: 1px solid #222;text-align: center; display: none">
                    Prepared By: <b>{{ transaction.created_user ? transaction.created_user.name : '' }}</b>
                </p>

                <div style="margin-top: 50px">
                    <span
                        style="color: #222;font-size: 11px; border-top: 1px solid #222; float: left">Received By</span>
                    <span style="color: #222;font-size: 11px; border-top: 1px solid #222; float: right;">Authorized Signature</span>
                </div>
            </div>
        </div>
        <p style="
            font-size: 9px;
            text-align: center;
            font-weight: bold;
            color: #35CD56;
            "
           >
            Powered by  <img class="mx-2" style="width: 65px" src="@/assets/softart.png" alt="">
        </p>
        <p style="color: #fff;font-size: 9px; text-align: center; background: rgb(53, 205, 86); padding:10px">
            হটলাইনঃ ০১৭২০৪২৫৪৮৫, ০১৭৭২১০২২৩৩ (সিরিয়ালের জন্য) ০১৭০৯২২২২৯৮ (হোয়াটসআপ) | <br>
            প্রতি ৬ মাস অন্তর অন্তর আপনার মূখ ও দাঁত দন্তরোগ বিশেষজ্ঞ দ্বারা পরীক্ষা করুন ।
        </p>
    </div>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
    name: "InvoicePrintView",
    data() {
        return {
            transaction: {},
        }
    },
    methods: {
        getInvoice(id) {
            ApiService.get(`/transactions/${id}`).then((res) => {
                this.transaction = res.data.data;
                setTimeout(() => {
                    this.printInvoice();
                }, 500)
            })
        },
        printInvoice() {
            this.$htmlToPaper('printInvoice', {
                styles: [
                    'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css',
                    '/../assets/css/invoicePrint.css',
                ]
            });
        },
    }
}
</script>
<style scoped>
.payment-css-paid {
    color: #fff;
    background-color: #28a745;
}

.payment-css-partial {
    color: #fff;
    background-color: #ffc107;
}

.payment-css-due {
    color: #fff;
    background-color: #dc3545;
}
</style>
