<template>
  <div class="ms-panel">
    <div class="ms-panel-header ms-panel-custom">
      <h6>Recent Prescription</h6>
    </div>
    <div class="ms-panel-body">
      <form @submit.prevent="$parent.getRecentPrescription()">
        <input type="search" v-model="search" autocomplete="off"
               class="form-control ms-form-input w-30" name="search" placeholder="Search" value="">
      </form>
      <table class="table table-striped table-hover thead-primary w-100 mt-2">
        <thead>
        <tr>
          <th scope="col">Patient</th>
        </tr>
        </thead>
        <tbody>
        <template v-if="$parent.recent_prescriptions.length">
          <tr v-for="(prescription, index) in $parent.recent_prescriptions"
              :class="{'draft' : prescription.status == 'draft' }">
            <td>
              <a @click="setInvoiceToPrescription(prescription)" href="javascript:void(0)">{{
                  prescription.patient ? prescription.patient.name
                                         + ' '
                                         + prescription.patient.patient_id : ''
                }}</a>
              <br>
              {{ prescription.date }}
            </td>
          </tr>
        </template>
        <template v-if="!$parent.recent_prescriptions.length">
          <tr>
            <th class="text-center font-weight-bolder" colspan="100%">No prescriptions found</th>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecentPrescription",
  data() {
    return {
      search: '',
    }
  },
  methods: {
    //setInvoiceToPrescription
    setInvoiceToPrescription(prescription) {
      let parent                     = this.$parent;
      //patient & doctor push
      parent.form.prescription       = prescription;
      parent.form.patient            = prescription.patient;
      parent.form.doctor             = prescription.doctor;
      parent.form.transactionable_id = prescription.id;

      //items pushing
      if (parent.editMode === false)
        parent.form.transaction_items = [];

      if (parent.form.type == 'service') {
        prescription.services.forEach((transaction_item, index) => {
          parent.addItem(transaction_item)
        })
      } else {
        prescription.investigations.forEach((transaction_item, index) => {
          parent.addItem(transaction_item)
        })
      }
    },
  },
}
</script>
<style>
.draft {
  background-color: #ffc107 !important;
}
</style>

