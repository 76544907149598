<template>
  <div class="modal fade" id="doctor-create-modal-id" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog ms-modal-dialog-width">
      <div class="modal-content ms-modal-content-width">
        <div class="modal-header  ms-modal-header-radius-0">
          <h4 class="modal-title text-white">Add Doctor</h4>
          <button type="button" class="close text-white" @click="closeModal()" data-dismiss="modal"
                  aria-hidden="true">x
          </button>
        </div>
        <div class="modal-body p-0 text-left">
          <div class="col-xl-12 col-md-12">
            <div class="ms-panel ms-panel-bshadow-none">
              <div class="ms-panel-body">
                <form class="needs-validation" @submit.prevent="store()">
                  <div class="form-row">
                    <div class="col-md-4 mb-3">
                      <label for="validationCustom001">Full Name</label>
                      <div class="input-group">
                        <input v-model="form.name" type="text" class="form-control"
                               id="validationCustom001"
                               placeholder="Full Name" required>
                      </div>
                    </div>
                    <div class="col-md-4 mb-3">
                      <drop-down v_model="specialist" title="Specialist Type" on_change=""/>
                    </div>
                    <div class="col-md-4 mb-3">
                      <drop-down :multiple="true" v_model="others_specialists" title="Others Specialist" on_change=""/>
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="validationCustom003">Mobile No.</label>
                      <div class="input-group">
                        <input required v-model="form.mobile" type="text" class="form-control"
                               id="validationCustom003" placeholder="Mobile">
                      </div>
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="validationCustom005">Email</label>
                      <div class="input-group">
                        <input v-model="form.email" type="email" class="form-control"
                               id="validationCustom005"
                               placeholder="Email">

                      </div>
                    </div>
                    <div class="col-md-4 mb-3">
                      <DatePicker title="Date of Birth" v_model="date_of_birth"/>
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="educations">Educations</label>
                      <div class="input-group">
                                    <textarea v-model="form.educations" class="form-control"
                                              id="educations"></textarea>
                      </div>
                    </div>

                    <div class="col-md-4 mb-3">
                      <label for="bmdc_number">BMDC Number</label>
                      <div class="input-group">
                        <input v-model="form.bmdc_number" type="text" class="form-control"
                               id="bmdc_number"
                               placeholder="BMDC Number">
                      </div>
                    </div>

                    <div class="col-md-4 mb-3">
                      <label>Gender</label>
                      <ul class="ms-list d-flex">
                        <li class="ms-list-item pl-0">
                          <label class="ms-checkbox-wrap">
                            <input v-model="form.gender" type="radio" name="radioExample"
                                   value="Male">
                            <i class="ms-checkbox-check"></i>
                          </label>
                          <span> Male </span>
                        </li>
                        <li class="ms-list-item">
                          <label class="ms-checkbox-wrap">
                            <input v-model="form.gender" type="radio" name="radioExample"
                                   value="Female" checked="">
                            <i class="ms-checkbox-check"></i>
                          </label>
                          <span> Female </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <button class="btn btn-warning mt-4 d-inline w-20">Save</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import DatePicker          from "@/components/picker/DatePicker";

export default {
  name      : "DoctorModalCreate",
  components: {DatePicker},
  data      : () => ({
    form: {
      name              : '',
      specialist        : '',
      others_specialists: [],
      mobile            : '',
      email             : '',
      educations        : '',
      bmdc_number       : '',
      blood_group       : '',
      role_id           : 3,
    },
  }),
  methods   : {
    store() {
      this.form.date_of_birth = this.form.date_of_birth ? moment(this.form.date_of_birth).format('YYYY-MM-DD') : '';

      this.form.specialist_id = this.form.specialist ? this.form.specialist.id : '';
      let others_specialists  = [];
      this.form.others_specialists.forEach(function (value, index) {
        if (value.id) {
          others_specialists.push(value.id);
        }
      });
      this.form.others_specialists = others_specialists;

      ApiService.post('/dropdown/doctors', this.form).then((res) => {
        NotificationService.success(res.data.message);
        this.$parent.$refs['drop_down_doctor'].getLists();
        this.closeModal();
        this.formReset();
        this.$parent.form.doctor = res.data.data;
      }).catch(error => {
        NotificationService.error(error.response.data.message);
      })
    },
    closeModal() {
      $('#doctor-create-modal-id').modal('hide');
    },
    openModal() {
      this.editMode = false;
      this.formReset();
      $('#doctor-create-modal-id').modal('show');
    },
    formReset() {
      this.form = {
        name              : '',
        specialist_id     : '',
        others_specialists: [],
        mobile            : '',
        email             : '',
        educations        : '',
        bmdc_number       : '',
        blood_group       : '',
        role_id           : 3,
      }
    },
  }
}
</script>

<style scoped>

</style>
