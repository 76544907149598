<template>
    <div id='bill-section' class="py-sm-5 py-3">
        <form @submit.prevent="editMode ? update() : store()">
            <div class="container">
                <div class="row">
                    <div class="col-sm-4">
                        <a @click.prevent="$router.go(-1)" href="" class="btn btn-primary btn-sm">Back</a>
                        <p>Patient ID: <span>{{ form.patient.id_number }}</span></p>
                        <p>Patient Name: <span>{{ form.patient.name }}</span></p>
                    </div>
                    <div class="col-sm-4"><h3 class="text-primary my-2 my-sm-0">Bill Create</h3></div>
                    <div class="col-sm-4">
                        <div class="d-flex align-items-baseline justify-content-between">
                            <span style="white-space: pre;">Doctor Name: </span>
                            <div class="w-100 ms-3 overflow-auto">
                                <template v-if="$root.user.role_name =='doctor'">
                                    {{ $root.user.name }}
                                </template>
                                <template v-else>
                                    <drop-down :class_name="{ 'is-invalid': errors['doctor_id'] }" title="Doctor"
                                               v_model="doctor"/>
                                    <div v-if="errors['doctor_id']" class="invalid-feedback">
                                        {{ errors['doctor_id'][0] }}
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="mt-3 pb-4 px-4 rounded shadow-lg">
                            <div class="row">
                                <div class="col-12 mt-sm-5 mt-3">
                                    <div class="row mb-3">
                                        <label for="visit" class="col-sm-2 col-form-label">Visit: </label>
                                        <div class="col-sm-4">
                                            <DateTimePicker :class_name="{ 'is-invalid': errors['transaction_date'] }"
                                                            class="w-100" title="Date" v_model="transaction_date"/>
                                            <div v-if="errors['transaction_date']" class="invalid-feedback">
                                                {{ errors['transaction_date'][0] }}
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <label class="col-sm-2 col-form-label">Visit fee:</label>
                                                <div class="col-sm-8">
                                                    <input :class="{ 'is-invalid': errors['visit_fee'] }" type="number"
                                                           v-model="form.visit_fee" class="form-control"
                                                           placeholder="Amount" min="0">
                                                    <div v-if="errors['visit_fee']" class="invalid-feedback">
                                                        {{ errors['visit_fee'][0] }}
                                                    </div>
                                                </div>
                                                <label class="col-sm-1 col-form-label">BDT</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-md-12 mb-3">
                                            <div class="row mb-3" v-for="(item, index) in form.transaction_items"
                                                 :key="index">
                                                <label class="col-sm-2 col-form-label">
                                                    Service {{ index + 1 }}:
                                                </label>
                                                <div class="mb-2 mb-sm-0 col-sm-4">
                                                    <multiselect
                                                        @search-change="getTransactionItems"
                                                        :class="{ 'is-invalid': errors[`transaction_items.${index}.service_id`] }"
                                                        label="name"
                                                        v-model="item.service"
                                                        :options="transaction_items"
                                                        placeholder="Select the Service"
                                                        :id="index"
                                                        @input="onChangeService"
                                                    />
                                                    <div v-if="errors[`transaction_items.${index}.service_id`]"
                                                         class="invalid-feedback">
                                                        {{ errors[`transaction_items.${index}.service_id`][0] }}
                                                    </div>
                                                </div>
                                                <div class="mb-2 mb-sm-0 col-sm-1">
                                                    <input
                                                        :class="{ 'is-invalid': errors[`transaction_items.${index}.quantity`] }"
                                                        type="number" v-model="item.quantity"
                                                        class="form-control"
                                                        placeholder="Quantity">
                                                    <div v-if="errors[`transaction_items.${index}.quantity`]"
                                                         class="invalid-feedback">
                                                        {{ errors[`transaction_items.${index}.quantity`][0] }}
                                                    </div>
                                                </div>
                                                <div class="mb-2 mb-sm-0 col-sm-2">
                                                    <input
                                                        :class="{ 'is-invalid': errors[`transaction_items.${index}.unit_price`] }"
                                                        type="number" v-model="item.unit_price"
                                                        class="form-control"
                                                        placeholder="Amount">
                                                    <div v-if="errors[`transaction_items.${index}.unit_price`]"
                                                         class="invalid-feedback">
                                                        {{ errors[`transaction_items.${index}.unit_price`][0] }}
                                                    </div>
                                                </div>
                                                <div class="mb-2 mb-sm-0 col-sm-2">
                                                    <input readonly :value="item.unit_price * item.quantity"
                                                           class="form-control" placeholder="Sub total">
                                                </div>
                                                <label class="col-sm-1 col-form-label">BDT
                                                    <a @click="form.transaction_items.splice(index,1)"
                                                       href="javascript:void(0)"><i
                                                        class="fa fa-minus-circle text-danger fs-20"></i></a>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-12 mb-3">
                                    <div @click="addItem" class="btn bg-primary text-white fw-bolder fs-4">
                                        +
                                    </div>
                                </div>
                                <div class="col-md-12 mb-3">
                                    <div class="row">
                                        <div class="col-md-6 mb-3">
                                            <label for="note">Notes</label>
                                            <div class="input-group">
                                                          <textarea :class="{ 'is-invalid': errors['note'] }" rows="3"
                                                                    v-model="form.note" class="form-control" id="note"
                                                                    placeholder="Notes"/>
                                                <div v-if="errors['note']"
                                                     class="invalid-feedback">
                                                    {{ errors['note'][0] }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 my-3">
                                            <div class="form-group row mb-2">
                                                <label for="sub_total" class="col-sm-3 col-form-label">Sub
                                                    Total</label>
                                                <div class="col-sm-6">
                                                    <input v-model="subTotal" readonly type="number"
                                                           class="form-control"
                                                           id="sub_total"
                                                           step="any"
                                                           placeholder="Sub Total">
                                                </div>
                                                <label class="col-sm-3 col-form-label">BDT</label>
                                            </div>

                                            <div class="form-group row mb-2">
                                                <label for="discount_percent"
                                                       class="col-sm-3 col-form-label">
                                                    Discount <span
                                                    v-if="form.discount_type === 'percentage'"> (%) </span>
                                                </label>
                                                <div class="col-sm-2 mb-3 mb-sm-0">
                                                    <input
                                                        @keyup="discountType('percentage')"
                                                        v-model="form.discount_percent" type="number"
                                                        class="d-inline-block form-control w-75"
                                                        id="discount_percent" step="any" min="0" max="99"
                                                        :class="{'bg-secondary':form.discount_type=='fixed', 'is-invalid': errors['discount_percent']}">%
                                                    <div v-if="errors['discount_percent']"
                                                         class="invalid-feedback">
                                                        {{ errors['discount_percent'][0] }}
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <input @keyup="discountType('fixed')" :max='subTotal' min="1"
                                                           v-model="form.discount_amount"
                                                           type="number" class="form-control"
                                                           id="discount_amount" step="any"
                                                           :class="{'bg-secondary':form.discount_type=='percentage', 'is-invalid': errors['discount_amount']}">
                                                    <div v-if="errors['discount_amount']"
                                                         class="invalid-feedback">
                                                        {{ errors['discount_amount'][0] }}
                                                    </div>
                                                </div>

                                                <label class="col-sm-3 col-form-label">BDT</label>
                                            </div>

                                            <div class="form-group row mb-2">
                                                <label for="gross_total" class="col-sm-3 col-form-label">Gross
                                                    Total</label>
                                                <div class="col-sm-6">
                                                    <input v-model="grossTotal" readonly type="number"
                                                           class="form-control"
                                                           id="gross_total"
                                                           step="any"
                                                           placeholder="Gross Total">
                                                </div>
                                                <label class="col-sm-3 col-form-label">BDT</label>
                                            </div>
                                            <div v-if="!editMode">
                                                <div class="form-group row mb-2">
                                                    <label for="payment_amount"
                                                           class="col-sm-3 col-form-label">Payment</label>
                                                    <div class="col-sm-6">
                                                        <input v-model="form.transaction_payments.amount"
                                                               type="number"
                                                               class="form-control"
                                                               id="payment_amount"
                                                               step="any"
                                                               placeholder="Payment">
                                                    </div>
                                                    <label class="col-sm-3 col-form-label">BDT</label>
                                                </div>
                                                <div class="form-group row mb-2">
                                                    <label for="due_amount" class="col-sm-3 col-form-label">Due
                                                        Amount</label>
                                                    <div class="col-sm-6">
                                                        <input
                                                            :value="grossTotal-form.transaction_payments.amount"
                                                            readonly type="number"
                                                            class="form-control"
                                                            id="due_amount"
                                                            step="any"
                                                            placeholder="Due Amount">
                                                    </div>
                                                    <label class="col-sm-3 col-form-label">BDT</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- button-->
                <div class="row">
                    <div class="mt-5 text-center d-flex gap-2 justify-content-center prescription-btn">
                        <button type="submit" class="bg-warning btn mt-3 px-5 py-3 text-light">
                            Print Bill
                        </button>
                        <button type="submit" class="bg-primary btn mt-3 px-5 py-3 text-light">
                            Money Receipt
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import Multiselect         from 'vue-multiselect';
import RecentPrescription  from "./inc/RecentPrescription";
import PatientModalCreate  from "./inc/PatientCreate";
import DoctorModalCreate   from "./inc/DoctorCreate";
import DatePicker          from "@/components/picker/DatePicker";
import DateTimePicker      from "@/components/picker/DateTimePicker";
import InvoicePrintView    from "./PrintView";
import moment              from "moment";

export default {
    name      : "InvoiceCreate",
    components: {
        DateTimePicker,
        DatePicker,
        DoctorModalCreate,
        PatientModalCreate,
        Multiselect,
        RecentPrescription,
        InvoicePrintView
    },
    data      : () => ({
        print_view          : false,
        patient_create_modal: false,
        doctor_create_modal : false,
        editMode            : false,
        item                : {},
        patient             : {
            name     : '',
            last_name: '',
        },
        errors              : [],
        form                : {
            type                : 'prescription',
            transactionable_id  : '',
            transactionable_type: "App\\Models\\Patient",
            transaction_date    : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            report_delivery_date: '',
            patient             : {
                name     : '',
                last_name: '',
            },
            doctor              : '',
            discount_type       : 'fixed',
            discount_percent    : '',
            discount_amount     : '',
            note                : '',
            visit_fee           : 0,
            final_total         : 0,
            gross_total         : 0,
            prescription        : '',
            transaction_items   : [
                {
                    service_id     : '',
                    service        : '',
                    quantity       : 1,
                    unit_price     : 0,
                    sub_total_price: 0,
                }
            ],
            transaction_payments: {
                amount: ''
            }
        },

        transaction_items   : [],
        recent_prescriptions: [],
    }),
    created   : function () {
        if (this.$route.name === 'invoiceEdit') {
            this.edit(this.$route.params.id);
        } else if (this.$route.query.patient_id) {
            this.getPatientData(this.$route.query.patient_id);
            this.getTransactionItems();
        }
    },
    mounted() {
        if (this.$root.user.role_name == 'doctor') {
            this.form.doctor = this.$root.user;
        }
    },
    computed: {
        selected_item_ids() {
            return this.form.transaction_items.map((item, index) => {
                return item.service.id;
            })
        },
        new_transaction_items() {
            return this.transaction_items.filter(item => !this.selected_item_ids.includes(item.id));
        },

        subTotal() {
            let total_amount = 0;
            this.form.transaction_items.forEach((item, i) => {
                total_amount += item.unit_price * item.quantity;
            });
            return (parseFloat(total_amount) + parseFloat(this.form.visit_fee));
        },
        grossTotal() {
            return this.subTotal - this.form.discount_amount;
        }
    },
    methods : {
        onChangeService(service, index) {
            this.form.transaction_items[index].unit_price = service.price;
            this.form.transaction_items[index].service_id = service.id;
        },
        getPatientData(id) {
            this.editMode = false;
            ApiService.get(`/patients/${id}`, {params: {request_type: 'prescription'}}).then((res) => {
                this.form.patient    = res.data.data
                this.form.patient_id = this.form.patient.id
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        discountType(type) {
            this.form.discount_type = type;
            if (type == 'percentage' && this.form.discount_percent != null) {
                this.form.discount_amount = this.subTotal * (this.form.discount_percent / 100);
            } else {
                this.form.discount_percent = 0
                this.form.discount_amount  = this.form.discount_amount
            }

        },
        addItem() {
            this.form.transaction_items.push({
                service_id     : '',
                service        : '',
                quantity       : 1,
                unit_price     : 0,
                sub_total_price: 0,
            });
        },
        store() {
            this.form.transactionable_id = this.form.patient ? this.form.patient.id : '';
            this.form.doctor_id          = this.form.doctor ? this.form.doctor.id : '';
            this.form.sub_total          = this.subTotal;
            this.form.final_total        = this.grossTotal;

            ApiService.post('/transactions', this.form).then((res) => {
                this.errors = []
                this.formReset();
                NotificationService.success(res.data.message);
                this.$router.push(`/invoice/view/${res.data.data.id}`);
                this.print(res.data.data.id)
            }).catch(error => {
                this.errors = error.response.data.errors;
                NotificationService.error(error.response.data.message);
            })
        },
        print(invoice_id) {
            this.print_view         = true;
            const component_created = setInterval(() => {
                if (this.$refs['print_view']) {
                    this.$refs['print_view'].getInvoice(invoice_id);
                    clearInterval(component_created);
                }
            }, 100);
        },
        edit(id) {
            this.editMode = true;
            ApiService.get(`/transactions/${id}`).then((res) => {
                this.form = res.data.data;
                this.getTransactionItems();
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        update() {
            this.form.transactionable_id = this.form.patient ? this.form.patient.id : '';
            this.form.doctor_id          = this.form.doctor ? this.form.doctor.id : '';
            this.form.sub_total          = this.subTotal;
            this.form.final_total        = this.grossTotal;

            ApiService.update(`/transactions/${this.form.id}`, this.form).then((res) => {
                this.errors = []
                this.formReset();
                NotificationService.success(res.data.message);
                this.$router.push(`/invoice/view/${res.data.data.id}`);
            }).catch(error => {
                this.errors = error.response.data.errors;
                NotificationService.error(error.response.data.message);
            })
        },
        getTransactionItems: function (search) {
            //this.formReset();
            let type = this.form.type;
            ApiService.get('/dropdown/services', {params: {type, search}}).then((res) => {
                this.transaction_items = res.data.data;
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        formReset() {
            this.form = {
                type                : 'prescription',
                transactionable_id  : '',
                transactionable_type: "App\\Models\\Patient",
                transaction_date    : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                report_delivery_date: '',
                patient             : '',
                doctor              : '',
                discount_type       : 'fixed',
                discount_percent    : '',
                discount_amount     : '',
                note                : '',
                visit_fee           : 0,
                final_total         : 0,
                gross_total         : 0,
                prescription        : '',
                transaction_items   : [
                    {
                        service_id     : '',
                        service        : '',
                        quantity       : 1,
                        unit_price     : 0,
                        sub_total_price: 0,
                    }
                ],
                transaction_payments: {
                    amount: ''
                }
            }
        }
    },
    watch   : {
        $route(to, from) {
            this.formReset();
        }
    }
}
</script>

<style scoped>
.table > :not(:first-child) {
    border-top: 0;
}
#bill-section{
    padding-bottom: 100px !important;
}
</style>
