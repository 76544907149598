<template>
  <div class="modal fade" id="patient-create-modal-id" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog ms-modal-dialog-width">
      <div class="modal-content ms-modal-content-width">
        <div class="modal-header  ms-modal-header-radius-0">
          <h4 class="modal-title text-white">Add Patient</h4>
          <button type="button" class="close text-white" @click="closeModal()" data-dismiss="modal"
                  aria-hidden="true">x
          </button>
        </div>
        <div class="modal-body p-0 text-left">
          <div class="col-xl-12 col-md-12">
            <div class="ms-panel ms-panel-bshadow-none">
              <div class="ms-panel-body">
                <form class="needs-validation" @submit.prevent="store()">
                  <div class="form-row">
                    <div class="col-md-4 mb-3">
                      <label for="name">Full Name</label>
                      <div class="input-group">
                        <input v-model="form.name" type="text" class="form-control"
                               id="name"
                               placeholder="Full Name" required>
                      </div>
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="phone">Mobile No.</label>
                      <div class="input-group">
                        <input type="text" v-model="form.mobile" class="form-control"
                               id="phone"
                               placeholder="Mobile Number" required>
                      </div>
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="email">Email</label>
                      <div class="input-group">
                        <input v-model="form.email" type="email" class="form-control"
                               id="email"
                               placeholder="Email">

                      </div>
                    </div>

                    <div class="col-md-3 mb-3">
                      <label>Gender</label>
                      <ul class="ms-list d-flex">
                        <li class="ms-list-item pl-0">
                          <label class="ms-checkbox-wrap">
                            <input v-model="form.gender" type="radio" name="radioExample"
                                   value="Male">
                            <i class="ms-checkbox-check"></i>
                          </label>
                          <span> Male </span>
                        </li>
                        <li class="ms-list-item">
                          <label class="ms-checkbox-wrap">
                            <input v-model="form.gender" type="radio" name="radioExample"
                                   value="Female" checked="">
                            <i class="ms-checkbox-check"></i>
                          </label>
                          <span> Female </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <button class="btn btn-warning mt-4 d-inline w-20">Save</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";

export default {
  name   : "PatientModalCreate",
  data   : () => ({
    form: {
      name   : '',
      mobile : '',
      email  : '',
      gender : '',
      role_id: 5,
    }
  }),
  methods: {
    store() {
      ApiService.post('/dropdown/patients', this.form).then((res) => {
        NotificationService.success(res.data.message);
        this.$parent.$refs['drop_down_patient'].getLists();
        this.closeModal();
        this.formReset();
        this.$parent.form.patient = res.data.data;
      }).catch(error => {
        NotificationService.error(error.response.data.message);
      })
    },
    closeModal() {
      $('#patient-create-modal-id').modal('hide');
    },
    openModal() {
      this.editMode = false;
      this.formReset();
      $('#patient-create-modal-id').modal('show');
    },
    formReset() {
      this.form = {
        name   : '',
        mobile : '',
        email  : '',
        gender : '',
        role_id: 5,
      }
    },
  }
}
</script>

<style scoped>

</style>
